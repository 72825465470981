import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'
import Layout from '../../components/Layout'
import { Container } from '../../components/Scaffold'
import { SOCIAL_LINKS } from '../../constants'

const ImageContainer = styled.figure`
  &:not(:first-child) {
    margin-top: 80px;
  }
`

export default ({ data }) => {
  const images = data.allFile.edges
  return (
    <Layout title='Photography - Faith Howley'>
      <Container maxWidth={640}>
        <h1>Photography</h1>
        <p>Taking photos is a complete passion project and something I would love to offer to my clients where possible. I'm trained in photography and have photographed projects for use on clients' websites.</p>
        <p>If you’re ever in need of some royalty free images for use wherever, check out my <a href={SOCIAL_LINKS.UNSPLASH} target="_blank" rel="noopener noreferrer">Unsplash page</a>.</p>
        {images.map(({ node }) => (
          <ImageContainer key={node.id}>
            <Img fluid={{...node.childImageSharp.fluid, aspectRatio: 4/3}} alt="" />
          </ImageContainer>
        ))}
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query {
    allFile(filter: { sourceInstanceName: { eq: "photos" } }) {
      edges {
        node {
          id
          childImageSharp {
            fluid(maxWidth: 960, maxHeight: 720) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
